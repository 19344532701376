.responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table td,
  .responsive-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .responsive-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .responsive-table th {
    text-align: left;
    background-color: #4caf50;
    color: white;
  }
  
  @media screen and (max-width: 600px) {
    .responsive-table {
      border: 0;
    }
    .responsive-table thead {
      display: none;
    }
    .responsive-table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }
    .responsive-table td {
      border: none;
      display: block;
      text-align: right;
      font-size: 13px;
    }
    .responsive-table td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    .responsive-table td:last-child {
      border-bottom: 0;
    }
  }

/* display sort class with arrow */

  .sort {
    cursor: pointer;
    color: #4caf50;
  }

  