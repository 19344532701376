/* reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* set default font */
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* set default text color */
body {
  color: #333;
}

/* set default background color */
body {
  background-color: #fff;
}

/* set default button style */
button {
  background-color: #007aff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  padding: 8px 16px;
}

/* set default form style */
form {
  margin: 16px 0;
}

/* set default label style */
label {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
}

/* set default input style */
input {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px;
  width: 100%;
}

/* set default pre style */
pre {
  background-color: #f6f8fa;
  border-radius: 4px;
  font-size: 14px;
  overflow-x: auto;
  padding: 16px;
}

/* set default table style */

table {
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* include padding and border in the total width and height of the table element */
}
/* set default th style */
th {
  border-bottom: 2px solid #ccc;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding: 8px;
}

/* set default td style */
td {
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  padding: 8px;
}

/* set default error style */
.error {
  color: #ff0000;
}

/* set default loading style */
.loading {
  color: #333;
  font-size: 14px;
}

/* set responsive styles */
@media (max-width: 768px) {
  /* set responsive form style */

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* set responsive label style */
  label {
    font-size: 12px;
    margin-bottom: 4px;
  }

  /* set responsive input style */
  input {
    font-size: 12px;
    padding: 4px;
  }

  /* set responsive pre style */
  pre {
    font-size: 12px;
    padding: 8px;
  }

  /* set responsive table style */
  table {
    border: 0;
  }

  /* set responsive th style */
  th {
    border: 0;
    font-size: 12px;
    padding: 4px;
  }

  /* set responsive td style */
  td {
    border: 0;
    font-size: 12px;
    padding: 4px;
  }

  /* set responsive error style */
  .error {
    font-size: 12px;
  }

  /* set responsive loading style */
  .loading {
    font-size: 12px;
  }
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.container {
  width: 100%;
}

@media (max-width: 768px) {
  .container textarea {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .container textarea {
    width: 60%;
  }
}
/* add loader class */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
